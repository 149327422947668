<template>
	<div class="loadingAnimation">
		<div class="inner">
			<img src="/images/HIVE-Loading.gif" alt="" />
			<p>Loading...</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "Loading Animation",
};
</script>

<style lang="scss" scoped>
.loadingAnimation {
	position: fixed;
	top: 0;
	left: 0;
	with: 100%;
	height: 100vh;
	background-color: var(--background);
	z-index: 1;

	.inner {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: fit-content;
		max-height: 50%;
		text-align: center;

		img {
			width: 50%;
			height: 200px;
			object-fit: contain;
			margin: auto;
		}
		p {
			font-size: 26px;
			font-weight: 400;
			color: var(--text);
			margin: 0;
			margin-top: 60px;
		}
	}
}
</style>
