<template>
	<nav>
		<div class="container">
			<div class="flex">
				<div class="branding">
					<a href="/">
						<img v-if="!menu" src="/images/hiveLogo.svg" alt="HIVE Logo" />
						<img v-else src="/images/hiveLogoDark.svg" alt="HIVE Logo" />
					</a>
				</div>
				<div class="hamburgerMenu" :class="{ active: menu }" @click="toggleMenu()">
					<svg width="50" height="50" viewBox="0 0 100 100">
						<path
							class="line line1"
							d="M 20,34 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
						/>
						<path class="line line2" d="M 20,50 H 80" />
						<path
							class="line line3"
							d="M 20,66.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
						/>
					</svg>
				</div>
				<div class="menu" :class="{ active: menu }">
					<div class="inner">
						<div class="container">
							<div class="content">
								<div class="group">
									<div class="splitter"></div>
									<a v-for="item in menuLinks" :key="item.id" :href="item.link">
										<p v-if="!item.zone.includes('GAN')">
											<span :style="'background-color:' + item.pageColour + ';'"></span>{{ item.name }}
										</p>
									</a>
								</div>
								<div class="splitter"></div>
								<div class="group">
									<a v-for="item in menuLinks" :key="item.id" :href="item.link">
										<p v-if="item.zone.includes('GAN')">
											<span :style="'background-color:' + item.pageColour + ';'"></span>{{ item.name }}
										</p>
									</a>
								</div>
								<div class="splitter"></div>
							</div>
							<div class="image">
								<img src="/images/UPS_Hive_Map_v4.png" alt="" />
							</div>
						</div>
						<div class="bgreydar">
							<p>
								{{ content.sometext }}<span>{{ content.reydartext }}</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
	<div class="bottomIcons">
		<div class="flex">
			<div
				class="circles contactForm"
				:class="{ 'd-none': bottomMenu === 'contact' }"
				@click="toggleBottomMenu('contact')"
			>
				<img src="/images/UPS_Contact.svg" alt="" />
			</div>
			<div class="circles waggleBot"></div>
			<div
				class="circles settings"
				:class="{ 'd-none': bottomMenu !== '' }"
				@click="toggleBottomMenu('settings')"
			>
				<img src="/images/UPS_Settings.svg" alt="" />
			</div>
			<div :class="{ 'd-none': bottomMenu === '' }" class="circles cross" @click="toggleBottomMenu('')">
				<img src="/images/UPS_Cross.svg" alt="" />
			</div>
		</div>
		<!-- <div class="flex" :class="{ 'd-none': bottomMenu === '' }">
			<div class="circles cross" @click="toggleBottomMenu('')">
				<img src="/images/UPS_Cross.svg" alt="" />
			</div>
		</div> -->
	</div>
	<div class="settingsMenu" v-if="bottomMenu === 'settings'">
		<div class="inner">
			<div class="header">
				<img src="/images/hiveLogoDark.svg" alt="" />
			</div>
			<div class="body">
				<h3>Language</h3>
				<div class="row">
					<div class="col-6" v-for="item in Languages" :key="item.id">
						<div
							class="flex"
							:class="{ active: currentLanguage === item.code }"
							@click="changeLanguage(item)"
						>
							<div class="icon">
								<img :src="'/images/Language-' + item.icon + '.png'" alt="" />
							</div>
							<p>{{ item.name }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="contactFormPopup" @click.self="toggleBottomMenu('')" v-if="bottomMenu === 'contact'">
		<div class="inner">
			<div class="closeButton" @click="toggleBottomMenu('')">
				<img src="/images/UPS_Cross.svg" alt="" />
			</div>
			<div class="header">
				<h2>Contact Us</h2>
				<p v-if="!formSuccess">
					Please fill out the form below and one of our representatives will be in touch shortly.
				</p>
			</div>
			<div class="body" v-if="!formSuccess">
				<form @submit.prevent="submitForm()">
					<div class="row">
						<div class="col-md-5">
							<a
								href="https://solutions.ups.com/virtual-consultation-discount-ussp-page.html"
								target="_blank"
							>
								<button class="w-100 consultationButton" type="button">Request a consultation</button>
							</a>
						</div>
					</div>
					<div class="row">
						<div class="col-md-5">
							<div class="formField">
								<input
									type="text"
									name="Country"
									id="Country"
									placeholder="Country*"
									@keyup="checkFields()"
									v-model="form.country"
								/>
							</div>
							<div class="formField">
								<input
									type="text"
									name="FirstName"
									id="FirstName"
									placeholder="First Name*"
									@keyup="checkFields()"
									v-model="form.firstName"
								/>
							</div>
							<div class="formField">
								<input
									type="text"
									name="LastName"
									id="LastName"
									placeholder="Last Name*"
									@keyup="checkFields()"
									v-model="form.lastName"
								/>
							</div>
							<div class="formField">
								<input
									type="text"
									name="EmailAddress"
									id="EmailAddress"
									placeholder="Email Address*"
									@keyup="checkFields()"
									v-model="form.emailAddress"
								/>
							</div>
							<div class="formField">
								<input
									type="text"
									name="PhoneNumber"
									id="PhoneNumber"
									placeholder="Phone Number*"
									@keyup="checkFields()"
									v-model="form.phoneNumber"
								/>
							</div>
						</div>
						<div class="col-md-7">
							<div class="formField textarea">
								<textarea
									name="YourEnquiry"
									id="YourEnquiry"
									placeholder="Your enquiry*"
									@keyup="checkFields()"
									v-model="form.enquiry"
								></textarea>
							</div>
						</div>
					</div>
					<div class="bottomBody">
						<div class="row">
							<div class="col-md-8">
								<div class="formCheckItem">
									<div class="singleCheck">
										<label for="terms">
											<input
												type="checkbox"
												name="terms"
												id="terms"
												@change="checkFields()"
												v-model="form.terms"
											/>
											<span></span>
											I agree to the Terms & Conditions and Privacy policy*
										</label>
									</div>
								</div>
								<div class="formCheckItem">
									<div class="singleCheck">
										<label for="personal">
											<input
												type="checkbox"
												name="personal"
												id="personal"
												@change="checkFields()"
												v-model="form.data"
											/>
											<span></span>
											I consent to UPS using my personal data for the purpose of fulfilling this contact
											request.*
										</label>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<button :class="{ disabled: !formSubmit }" :disabled="!formSubmit" @click="submitForm()">
									Send enquiry
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div class="body" v-else>
				<p>Thank you, someone will be in touch with you shortly</p>
			</div>
		</div>
	</div>
</template>

<script>
window.__be = window.__be || {};
window.__be.id = "611f8fef3838b50007ee7546";
(function() {
	var be = document.createElement("script");
	be.type = "text/javascript";
	be.async = true;
	be.src =
		("https:" == document.location.protocol ? "https://" : "http://") + "cdn.chatbot.com/widget/plugin.js";
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(be, s);
})();

import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import axios from "axios";

export default {
	name: "Navigation",
	setup() {
		const store = useStore();
		/* ----- DATA -------------------- */
		const menu = ref(false);
		const content = ref({
			button: "Visit Expo 2020 Dubai",
			sometext: "This Virtual Event is powered by",
			reydartext: "REYDAR",
		});
		const menuLinks = ref(computed(() => store.state.navigation.menu));
		const currentLanguage = ref(computed(() => store.state.language.language));

		const formSubmit = ref(false);
		const formSuccess = ref(false);
		const form = ref({
			contactType: "consultation",
			country: "",
			firstName: "",
			lastName: "",
			emailAddress: "",
			phoneNumber: "",
			enquiry: "",
			terms: false,
			data: false,
		});

		const bottomMenu = ref("");
		const Languages = ref([
			{
				id: 1,
				name: "English",
				icon: "English",
				code: "en-GB",
			},
			{
				id: 2,
				name: "Arabic",
				icon: "Arabic",
				code: "ar",
			},
			{
				id: 3,
				name: "Mandarin",
				icon: "Mandarin",
				code: "zh",
			},
			{
				id: 4,
				name: "French",
				icon: "French",
				code: "fr",
			},
			{
				id: 5,
				name: "German",
				icon: "German",
				code: "de",
			},
			{
				id: 6,
				name: "Spanish",
				icon: "Spanish",
				code: "es",
			},
		]);

		/* ----- Methods -------------------- */
		const checkCheckboxes = (value) => {
			if (value === "consultation") {
				document.getElementById("consultation").checked = true;
				document.getElementById("moreInfo").checked = false;
				checkFields();
			} else if (value === "moreInfo") {
				document.getElementById("consultation").checked = false;
				document.getElementById("moreInfo").checked = true;
				checkFields();
			} else {
				document.getElementById("consultation").checked = false;
				document.getElementById("moreInfo").checked = false;
				checkFields();
			}
		};
		const toggleMenu = () => {
			menu.value = !menu.value;
			if (menu.value === true) {
				document.body.style.overflowY = "hidden";
			} else {
				document.body.style.overflowY = "auto";
			}
		};
		const toggleBottomMenu = (menuOption) => {
			bottomMenu.value = menuOption;

			if (menuOption === "contact") {
				menu.value = false;
			}

			if (bottomMenu.value === "") {
				document.body.style.overflowY = "auto";
			} else {
				document.body.style.overflowY = "hidden";
			}
		};
		const changeLanguage = (item) => {
			store.commit("language/setLanguage", item.code);
			toggleBottomMenu("");
		};
		const checkFields = () => {
			console.log("Checking Fields");
			if (
				form.value.contactType !== "" &&
				form.value.country !== "" &&
				form.value.firstName !== "" &&
				form.value.lastName !== "" &&
				form.value.emailAddress !== "" &&
				form.value.phoneNumber !== "" &&
				form.value.enquiry !== "" &&
				form.value.terms &&
				form.value.data
			) {
				formSubmit.value = true;
			} else {
				formSubmit.value = false;
			}
		};
		const submitForm = () => {
			if (formSubmit.value) {
				let consolt = true;

				if (form.value.contactType === "consultation") {
					consolt = true;
				} else {
					consolt = false;
				}

				let s = "<br><br>";
				let dataValue =
					"First Name: " +
					form.value.firstName +
					s +
					"Last Name " +
					form.value.lastName +
					s +
					"Email " +
					form.value.emailAddress +
					s +
					"Country " +
					form.value.country +
					s +
					"Phone Number" +
					form.value.phoneNumber +
					s +
					"Agreed to T&Cs " +
					form.value.terms +
					s +
					"Consent to Data " +
					form.value.data +
					s +
					"Enquiry " +
					form.value.enquiry;

				let data = {
					data: dataValue,
					fromEmailAddress: form.value.emailAddress,
					fromEmailName: form.value.firstName + " " + form.value.lastName,
					subject: "HIVE by UPS - Enquiry Form",
					toEmail: "hive@1920ww.com",
					toEmailName: "UPS Enquiry Form",
				};
				axios({
					method: "post",
					url: "https://api.reydar.com/api/Company/PostEnquiryContact",
					headers: {
						"content-type": "application/json",
					},
					data: data,
				})
					.then(() => {
						formSuccess.value = true;
						setTimeout(function() {
							bottomMenu.value = "";
						}, 5000);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		};

		onMounted(() => {
			window.BE_API = window.BE_API || {};
			if (window.BE_API.isChatWindowHidden() === true) {
				window.BE_API.openChatWindow();
			} else {
				window.BE_API.closeChatWindow();
			}
		});

		return {
			form,
			menu,
			content,
			menuLinks,
			bottomMenu,
			Languages,
			toggleMenu,
			currentLanguage,
			toggleBottomMenu,
			changeLanguage,
			checkCheckboxes,
			formSubmit,
			checkFields,
			formSuccess,
			submitForm,
		};
	},
};
</script>

<style lang="scss" scoped>
nav {
	position: absolute;
	top: 60px;
	left: 0;
	width: 100%;
	height: 70px;
	z-index: 9;

	.flex {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;

		.branding {
			max-width: 50%;
			height: 70px;
			margin-top: -250px;

			animation-name: branding;
			animation-iteration-count: 1;
			animation-duration: 0.8s;
			animation-delay: 0.5s;
			animation-fill-mode: forwards;

			img {
				height: 70px;
				width: 100%;
			}
		}
		.hamburgerMenu {
			position: relative;
			width: 60px;
			height: 60px;
			background-color: var(--background);
			border-radius: 50%;
			padding: 5px;
			cursor: pointer;
			margin-top: -250px;
			transition: 1s ease;

			animation-name: hamburgerMenu;
			animation-iteration-count: 1;
			animation-duration: 0.8s;
			animation-delay: 0.95s;
			animation-fill-mode: forwards;

			svg {
				path {
					transition: 1s ease;
				}
			}

			.line {
				fill: none;
				stroke: black;
				stroke-width: 6;
				transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
					stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
			}
			.line1 {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
			.line2 {
				stroke-dasharray: 60 60;
				stroke-width: 6;
			}
			.line3 {
				stroke-dasharray: 60 207;
				stroke-width: 6;
			}
		}
		.hamburgerMenu.active {
			background: var(--cta);

			svg {
				path {
					stroke: var(--background);
				}
			}

			.line1 {
				stroke-dasharray: 90 207;
				stroke-dashoffset: -134;
				stroke-width: 6;
			}
			.line2 {
				stroke-dasharray: 1 60;
				stroke-dashoffset: -30;
				stroke-width: 6;
			}
			.line3 {
				stroke-dasharray: 90 207;
				stroke-dashoffset: -134;
				stroke-width: 6;
			}
		}
		.menu {
			position: fixed;
			top: 0;
			left: -100%;
			width: 100%;
			height: 100vh;
			background-color: var(--background);
			padding-top: 190px;
			transition: 1s ease;
			z-index: -1;

			.inner {
				position: relative;
				min-height: calc(100vh - 190px - 85px);
				max-height: calc(100vh - 190px - 85px);
				overflow-y: auto;

				.content {
					display: inline-flex;
					flex-direction: column;

					.splitter {
						background-color: rgba(0, 0, 0, 0.5);
					}
					a {
						font-size: 18px;
						font-weight: 700;
						color: var(--text);
						text-decoration: none;

						p {
							position: relative;
							font-size: 18px;
							font-weight: 700;
							color: var(--text);
							margin: 0;
							margin-bottom: 30px;

							span {
								position: absolute;
								top: 0;
								left: -20px;
								bottom: 0;
								margin: auto;
								width: 10px;
								height: 10px;
								border-radius: 50%;
								background-color: red;
							}
						}
					}
					button {
						margin-bottom: 30px;
					}
				}
				.image {
					display: inline-flex;
					float: right;
					max-width: 40%;
					height: 60vh;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				.bgreydar {
					position: fixed;
					left: -100%;
					bottom: 0;
					width: 100%;
					padding: 30px;
					background-color: var(--background-alt);
					transition: 1s ease;

					p {
						margin: 0px;

						span {
							font-family: proxima-nova, sans-serif;
							padding-left: 5px;
						}
					}
				}
			}
		}
		.menu.active {
			left: 0;

			.bgreydar {
				left: 0;
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	nav {
		top: 30px;
		height: 60px;

		.flex {
			.branding {
				img {
					height: 60px;
				}
			}
			.hamburgerMenu {
				width: 45px;
				height: 45px;

				svg {
					width: 35px;
					height: 35px;
				}
			}
			.menu {
				overflow-y: auto;
				padding-top: 130px;

				.inner {
					padding-bottom: 60px;

					.content {
						display: flex;
						button {
							width: 100%;
						}
					}
					.image {
						display: none;
					}
				}
			}
		}
	}
}
.bottomIcons {
	position: fixed;
	right: 60px;
	bottom: 60px;
	height: 60px;
	z-index: 10;

	.flex {
		display: flex;

		.circles {
			position: relative;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: var(--background);
			margin-left: 30px;
			overflow: hidden;
			transition: 0.6s ease;
			cursor: pointer;
			z-index: 8;
		}
		.circles:hover {
			background-color: var(--cta);
		}
		.circles.waggleBot {
			background-color: var(--cta);
		}
	}
}
@media only screen and (max-width: 768px) {
	.bottomIcons {
		right: 15px;
		bottom: 15px;
		width: 45px;
		height: auto;

		.flex {
			flex-direction: column;
			flex-wrap: nowrap;
			align-content: center;
			align-items: center;

			.circles {
				width: 45px;
				height: 45px;
				margin-left: 0px;
				margin-top: 15px;
			}
		}
	}
}
.settingsMenu {
	position: fixed;
	right: 90px;
	bottom: 90px;
	z-index: 7;

	.inner {
		max-width: 15vw;
		width: 80%;
		min-width: 400px;
		background-color: var(--background);
		margin-left: auto;
		overflow: hidden;

		.header {
			padding: 15px;
			border-bottom: solid 1px rgba(0, 0, 0, 0.3);

			img {
				width: 100%;
				height: 30px;
				object-fit: contain;
				object-position: left;
			}
		}
		.body {
			padding: 15px;

			h3 {
				font-size: 22px;
				font-weight: 800;
				color: var(--text);
				margin: 0;
				margin-bottom: 15px;
				margin-top: 15px;
			}
			.flex {
				display: flex;
				padding: 10px;
				margin-bottom: 10px;
				cursor: pointer;
				transition: 0.6s ease;

				.icon {
					width: 20px;
					height: 20px;
					margin-right: 10px;

					img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
				p {
					font-size: 16px;
					font-weight: 400;
					color: var(--text);
					margin: 0;
				}
			}
			.flex.active,
			.flex:hover {
				background-color: var(--background-alt);
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	.settingsMenu {
		left: 0;
		right: 0;
		bottom: 60px;
		width: 100%;

		.inner {
			max-width: calc(100% - 60px);
			width: calc(100% - 60px);
			min-width: calc(100% - 60px);
			margin: auto;
		}
	}
}
.contactFormPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	z-index: 11;

	.inner {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 60%;
		height: auto;
		height: fit-content;
		max-height: 80%;
		background-color: var(--background);
		overflow: hidden;
		overflow-y: auto;

		.closeButton {
			position: absolute;
			top: 30px;
			right: 30px;
			width: 45px;
			height: 45px;
			background-color: var(--cta);
			border-radius: 50%;
			cursor: pointer;
		}

		.header {
			padding: 30px;

			h2 {
				font-size: 36px;
				font-weight: 700;
				color: var(--text);
				margin: 0;
				margin-bottom: 15px;
			}
			p {
				font-size: 18px;
				font-weight: 400;
				color: var(--text);
				margin: 0;
			}
		}
		.body {
			padding: 30px;

			form {
				.formCheck {
					display: flex;
					margin-bottom: 30px;

					.singleCheck {
						margin-right: 30px;

						label {
							display: flex;
							align-content: center;
							align-items: center;
							font-size: 18px;
							font-weight: 400;
							color: var(--text);
							cursor: pointer;

							input {
								display: none;
								opacity: 0;
								position: relative;
								z-index: -9999;
							}
							span {
								display: inline-block;
								width: 20px;
								height: 20px;
								border: solid 3px var(--background);
								outline: solid 2px var(--cta);
								background-color: var(--background);
								border-radius: 50%;
								margin-right: 15px;
							}
							input:checked + span {
								background-color: var(--cta);
							}
						}
					}
				}

				.bottomBody {
					margin-top: 30px;

					.formCheckItem {
						display: flex;

						.singleCheck {
							margin-right: 30px;

							label {
								display: flex;
								align-content: center;
								align-items: center;
								font-size: 18px;
								font-weight: 400;
								color: var(--text);
								cursor: pointer;

								input {
									display: none;
									opacity: 0;
									position: relative;
									z-index: -9999;
								}
								span {
									display: inline-block;
									width: 20px;
									height: 20px;
									border: solid 3px var(--background);
									outline: solid 2px var(--cta);
									background-color: var(--background);
									// border-radius: 50%;
									margin-right: 15px;
								}
								input:checked + span {
									background-color: var(--cta);
								}
							}
						}
					}
					button {
						width: 100%;
					}
					button.disabled {
						opacity: 0.5;
						cursor: no-drop;
					}
					button.disabled {
						font-size: 22px;
						font-weight: 700;
						color: var(--background);
						background-color: var(--cta);
						border: none;
						border-radius: 30px;
						padding: 10px 45px;
						transition: 0.6s ease;
					}
				}
				button.consultationButton {
					width: 100%;
					margin-bottom: 30px;
				}
				.formField {
					margin-bottom: 20px;

					input,
					textarea {
						width: 100%;
						border: solid 2px #d8d9d9;
						padding: 8px;
						font-size: 18px;
						font-weight: 400;
						color: var(--text);
						outline: none;
					}
					textarea {
						height: 100%;
						resize: none;
					}
				}
				.formField:last-child {
					margin-bottom: 0;
				}
				.formField.textarea {
					height: 100%;
				}
			}
			p {
				font-size: 18px;
				font-weight: 400;
				color: var(--text);
				margin: 0;
			}
		}
	}
}
@media only screen and (max-width: 1200px) {
	.contactFormPopup {
		.inner {
			width: calc(100% - 120px);
		}
	}
}
@media only screen and (max-width: 768px) {
	.contactFormPopup {
		background-color: rgba(0, 0, 0, 0.3);

		.inner {
			width: calc(100% - 60px);
			height: calc(100% - 120px);
			overflow: hidden;
			overflow-y: auto;

			.closeButton {
				top: 20px;
				right: 20px;
				width: 35px;
				height: 35px;
			}

			.header {
				h2 {
					font-size: 26px;
					font-weight: 700;
					color: var(--text);
					margin: 0;
					margin-bottom: 15px;
				}
				p {
					font-size: 16px;
					font-weight: 400;
					color: var(--text);
					margin: 0;
				}
			}
			.body {
				padding: 30px;

				form {
					.formCheck {
						display: block;

						.singleCheck {
							label {
								font-size: 16px;

								span {
									width: 15px;
									height: 15px;
									border: solid 2px var(--background);
								}
							}
						}
					}

					.bottomBody {
						margin-top: 30px;

						.formCheckItem {
							display: block;

							.singleCheck {
								label {
									font-size: 16px;

									span {
										width: 15px;
										height: 15px;
										border: solid 2px var(--background);
									}
								}
							}
						}
						button {
							font-size: 18px;
							margin-top: 15px;
						}
						button.disabled {
							opacity: 0.5;
							cursor: no-drop;
						}
					}
					.formField:last-child {
						margin-bottom: 20px;
					}
				}
			}
		}
	}
}

/* ----- Animations -------------------- */
@keyframes branding {
	0% {
		margin-top: -250px;
	}
	100% {
		margin-top: 0;
	}
}
@keyframes hamburgerMenu {
	0% {
		margin-top: -250px;
	}
	100% {
		margin-top: 0;
	}
}
</style>

<style lang="scss">
#chatbot-chat {
	position: fixed !important;
	right: 130px !important;
	bottom: 40px !important;
}
@media only screen and (max-width: 768px) {
	#chatbot-chat {
		right: -12px !important;
		bottom: 47px !important;
		transform: scale(0.75);
	}
}
</style>
