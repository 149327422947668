import store from "@/_store";
import axios from "axios";

const state = () => ({
	menu: [],
	firstSlide: [],
	secondSlide: [],
	loaded: false,
});

const mutations = {
	getNavigationMenu(state) {
		axios({
			method: "get",
			url:
				"https://preview.contentful.com/spaces/b9cqxo9t02fq/environments/master/entries?access_token=Qh_7dhMqrxmWrDe2zFMk-WCpI9pj9Hm0upPX_DHPBeI&content_type=navigationBlock&locale=" +
				store.state.language.language,
			headers: {
				"content-type": "application/json",
			},
		})
			.then((response) => {
				let links = [];
				let data = response.data.includes.Entry;
				let assets = response.data.includes.Asset;

				for (let i = 0; i < data.length; i++) {
					let thisLink = {
						id: i,
						name: data[i].fields.displayTitle,
						zone: data[i].fields.zone,
						link: data[i].fields.navigationSlug,
						sliderImage: {
							id: data[i].fields.sliderImage.sys.id,
						},
						heroCopy: data[i].fields.heroCopy,
						pageColour: data[i].fields.pageColor,
					};
					if (data[i].fields.displayInSlider) {
						thisLink.slider = true;
					} else {
						thisLink.slider = false;
					}
					links.push(thisLink);
				}

				for (let i = 0; i < links.length; i++) {
					if (links[i].sliderImage) {
						for (let a = 0; a < assets.length; a++) {
							if (links[i].sliderImage.id === assets[a].sys.id) {
								links[i].sliderImage.fileURL = assets[a].fields.file.url;
								links[i].sliderImage.name = assets[a].fields.title;
							}
						}
					}
				}

				console.log("links"), links;

				state.menu = links.sort((a, b) => a.name.localeCompare(b.name));

				state.firstSlide = [];
				state.secondSlide = [];

				for (let i = 0; i < state.menu.length; i++) {
					if (state.menu[i].zone.includes("GAN")) {
						state.secondSlide.push(state.menu[i]);
					} else {
						state.firstSlide.push(state.menu[i]);
					}
				}
				state.loaded = true;
			})
			.catch((err) => {
				console.log(err);
			});
	},
};

// actions
const actions = {
	getNavigationMenu(context) {
		context.commit("getNavigationMenu");
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
