import { createRouter, createWebHistory } from "vue-router";

const routes = [
	// INJECT: Page Template
	{
		path: "/",
		name: "PageTemplateRoot",
		component: () => import("@/views/homePage"),
	},
	{
		path: "/:pageName",
		name: "PageTemplate",
		component: () => import("@/views/page"),
	},
	// {
	// 	path: '/json',
	// 	name: 'PageTemplateRoot',
	// 	component: () => import('@/views/json'),
	// },
	// INJECT:Page Not Found
	{
		// path: "*",
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: () => import("@/views/pageNotFound"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
