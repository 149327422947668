import store from "@/_store";
import axios from "axios";
import marked from "marked";

const state = () => ({
	pageTitle: "loading",
	header: null,
	data: null,
	loaded: false,
	pageColour: "",
});

const mutations = {
	getData(state) {
		let content = [];
		let fullContent = [];
		let links = [];
		let assets = [];
		let reBuild;
		let header;

		axios({
			method: "get",
			url:
				"https://preview.contentful.com/spaces/b9cqxo9t02fq/environments/master/entries?access_token=Qh_7dhMqrxmWrDe2zFMk-WCpI9pj9Hm0upPX_DHPBeI&content_type=hiveLiteHomepage&locale=" +
				store.state.language.language,
			headers: {
				"content-type": "application/json",
			},
		})
			.then((response) => {
				state.loaded = true;

				let data = response.data.includes.Entry;
				let assetPath = response.data.includes.Asset;
				state.pageTitle = response.data.items[0].fields.displayTitle;
				state.pageColour = response.data.items[0].fields.pageColor;

				if (response.data.items[0].fields.heroTitle) {
					header = {
						title: response.data.items[0].fields.heroTitle,
						content: response.data.items[0].fields.overview,
						button: response.data.items[0].fields.heroButtonText,
						buttonURL: response.data.items[0].fields.heroButtonUrl,
						buttonType: 1,
					};
				}

				for (let i = 0; i < data.length; i++) {
					if (
						data[i].fields.blockTitle &&
						data[i].fields.blockTitle !== null &&
						data[i].fields.blockTitle !== ""
					) {
						data[i].fields.uId = data[i].sys.id;
						content.push(data[i].fields);
						fullContent.push(data[i]);
					}
					if (data[i].fields.linkURL && data[i].fields.linkURL !== null && data[i].fields.linkURL !== "") {
						links.push(data[i].fields);
					}
					if (data[i].fields.mediaURL && data[i].fields.mediaURL !== null && data[i].fields.mediaURL !== "") {
						assets.push(data[i]);
					}
				}

				for (let i = 0; i < content.length; i++) {
					let order = response.data.items[0].fields.contentBlocks;

					for (let o = 0; o < order.length; o++) {
						if (order[o].sys.id === content[i].uId) {
							content[i].orderBy = o;
						}
					}
				}

				for (let i = 0; i < assetPath.length; i++) {
					if (
						assetPath[i].fields.file.url &&
						assetPath[i].fields.file.url !== null &&
						assetPath[i].fields.file.url !== ""
					) {
						links.push(assetPath[i]);
					}
				}

				for (let i = 0; i < links.length; i++) {
					if (links[i].sys.id === response.data.items[0].fields.heroImage.sys.id) {
						header.fileURL = links[i].fields.file.url;
						// header.text = links[i].heroCopy;
					}
				}

				state.header = header;

				for (let i = 0; i < content.length; i++) {
					content[i].buttons = [];
					content[i].images = [];
					content[i].videos = [];

					for (const objectData in content[i]) {
						if (`${content[i][objectData]}`.includes("#")) {
							let formatted = marked(`${content[i][objectData]}`);
							content[i][objectData] = formatted;
						}
						if (`${content[i][objectData]}`.includes("{{")) {
							let splitRule = /{{(.*?)}}/;
							let split = `${content[i][objectData]}`.split(splitRule);

							for (let s = 0; s < split.length; s++) {
								for (let l = 0; l < links.length; l++) {
									if (links[l].id === split[s]) {
										let data = { buttonText: links[l].linkTitle, buttonLink: links[l].linkURL };
										content[i].buttons.push(data);
										split[s] = "";
									}
								}
							}
							for (let s = 0; s < split.length; s++) {
								reBuild = marked(reBuild + split[s]);
							}
							content[i][objectData] = reBuild
								.replace("undefined", "")
								.replace("# ", "")
								.replace("#", "")
								.replace("\n\n", "<br><br>")
								.replace("\n", "<br>");
						}
					}

					if (content[i].mediaContent) {
						for (let m = 0; m < content[i].mediaContent.length; m++) {
							for (let a = 0; a < assets.length; a++) {
								if (content[i].mediaContent[m].sys.id === assets[a].sys.id) {
									if (assets[a].fields.type === "Image") {
										let mediaObject = {
											mediaTitle: assets[a].fields.mediaTitle,
											mediaDescription: assets[a].fields.mediaDescription,
											mediaURL: assets[a].fields.mediaURL,
											// mediaURL: "/images/home-header-image.jpg",
										};
										content[i].images.push(mediaObject);
									} else if (assets[a].fields.type === "Video") {
										let mediaObject = {
											mediaTitle: assets[a].fields.mediaTitle,
											mediaDescription: assets[a].fields.mediaDescription,
											mediaURL: assets[a].fields.mediaURL,
											// mediaURL: "/videos/placeholder.mp4",
										};
										content[i].videos.push(mediaObject);
									}
								}
							}
						}
					}
					if (content[i].mediaUpload) {
						for (let m = 0; m < links.length; m++) {
							if (links[m].sys.id === content[i].mediaUpload.sys.id) {
								if (links[m].fields.file.url.includes(".jpg") || links[m].fields.file.url.includes(".png")) {
									content[i].images.push({
										name: links[m].fields.title,
										fileURL: links[m].fields.file.url,
									});
								} else {
									content[i].videos.push({
										name: links[m].fields.title,
										fileURL: links[m].fields.file.url,
									});
								}
							}
						}
					}
					if (content[i].videoPosterImage) {
						for (let m = 0; m < links.length; m++) {
							if (links[m].sys.id === content[i].videoPosterImage.sys.id) {
								content[i].videoThumbnail = links[m].fields.file.url;
							}
						}
					}
				}

				for (let i = 0; i < content.length; i++) {
					if (content[i].blockStyle === "Promo") {
						if (content[i].images.length > 0) {
							content[i].blockType = "imageBlock";
						} else if (content[i].videos.length > 0) {
							content[i].blockType = "videoBlock";
						} else {
							content[i].blockType = "Expanded";
						}
					} else {
						content[i].blockType = content[i].blockStyle;
					}
				}

				state.data = content;
				store.commit("pageContent/loaded");
			})
			.catch((err) => {
				console.log(err);
			});
	},
};

// actions
const actions = {
	getData(context, data) {
		context.commit("getData", data);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
