import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/_router'
import store from '@/_store'
import { createHead } from '@vueuse/head'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/scss/_static/import.scss'

const head = createHead()

createApp(App)
	.use(router)
	.use(store)
	.use(head)
	.mount('#app')
