import store from '@/_store'

const state = () => ({
  data: {
    popup: false,
    videoURL: '',
    imageURL: ''
  },
})

const mutations = {
  updatePopup(state, data) {
    if(data.status === 'openVideo'){
      store.commit('popupVideo/openVideo', {
        url: data.videoURL
      })
    } else if(data.status === 'openImage'){
      store.commit('popupVideo/openImage', {
        url: data.videoURL
      })
    } else{
      store.commit('popupVideo/closePopup')
    }
  },
  openVideo(state, data){
    state.data.popup = true
    state.data.videoURL = data.url
  },
  openImage(state, data){
    state.data.popup = true
    state.data.imageURL = data.url
  },
  closePopup(state){
    state.data.popup = false
    state.data.videoURL = '',
    state.data.imageURL = ''
  }
}

// actions
const actions = {
  updatePopup(context, data) {
    context.commit('updatePopup', data)
  },
  openVideo(context, data) {
    context.commit('openVideo', data)
  },
  openImage(context, data) {
    context.commit('openImage', data)
  },
  closePopup(context) {
    context.commit('closePopup')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
