<template>
	<div class="popup" v-if="loaded">
		<div class="popupInner">
			<div class="heading">
				<h3>{{ content.title }}</h3>
				<div class="closeButton" @click="closePopup()">
					<div class="item"></div>
				</div>
			</div>
			<div class="body">
				<h4>{{ content.heading }}</h4>
				<p v-html="content.text"></p>
				<div class="logos">
					<img src="/images/edge_logo.png" alt="" />
					<img src="/images/Opera_Logo.png" alt="" />
					<img src="/images/Chrome_Logo.png" alt="" />
					<img src="/images/Firefox_logo.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
	name: "Browser Popup",
	setup() {
		const store = useStore();

		/* ----- Data -------------------- */
		const loaded = ref(false);
		const content = ref({
			title: "Browser",
			heading: "Access HIVE by UPS Virtual Event by changing your browser",
			text:
				'The HIVE by UPS Virtual Event is a highly immersive 3D experience. Please re-visit using the latest version of <a href="#chrome">Chrome</a>, <a href="#Edge">Edge</a>, <a href="#Firefox">Firefox</a> or <a href="#Opera">Opera</a> browsers.',
		});

		/* ----- Methods -------------------- */
		const closePopup = () => {
			store.commit("popupBrowser/closePopup");
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			loaded.value = true;
			document.body.style.overflowY = "hidden";
		});

		return {
			loaded,
			content,
			closePopup,
		};
	},
};
</script>

<style lang="scss">
.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10;

	.popupInner {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 60%;
		height: fit-content;
		max-height: 75vh;
		background-color: var(--background);
		overflow: hidden;
		overflow-y: auto;

		.heading {
			position: relative;
			padding: 45px;
			box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);

			.closeButton {
				position: absolute;
				top: 20px;
				right: 20px;
				width: 45px;
				height: 45px;
				border-radius: 50%;
				background-color: var(--cta);
				cursor: pointer;

				.item {
					position: relative;
					width: 45px;
					height: 45px;
				}
				.item:before,
				.item:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					width: 25px;
					height: 2px;
					background-color: var(--background);
					transform: rotate(45deg);
				}
				.item:after {
					transform: rotate(-45deg);
				}
			}
			h3 {
				font-size: 32px;
				font-weight: 700;
				color: var(--text);
				margin: 0;
			}
		}
		.body {
			padding: 45px;

			h4 {
				font-size: 22px;
				font-weight: 700;
				color: var(--text);
				margin: 0;
				margin-bottom: 22.5px;
			}
			p {
				font-size: 16px;
				font-weight: 400;
				color: var(--text);
				margin: 0;
				margin-bottom: 45px;

				a {
					text-decoration: underline;
					color: var(--cta);
				}
			}
			.logos {
				display: flex;

				img {
					width: 45px;
					height: 45px;
					object-fit: contain;
					margin-right: 20px;
				}
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.popup {
		.popupInner {
			width: 90%;
			max-height: 95vh;

			.heading {
				padding: 20px;

				.closeButton {
					top: 15px;
					right: 15px;
					width: 35px;
					height: 35px;

					.item {
						width: 35px;
						height: 35px;
					}
					.item:before,
					.item:after {
						width: 2px;
						height: 20px;
					}
				}

				h3 {
					font-size: 22px;
				}
			}
			.body {
				padding: 20px;

				h4 {
					font-size: 22px;
				}
				p {
					font-size: 16px;
				}
				.logos {
					img {
						width: 30px;
						height: 30px;
					}
				}
			}
		}
	}
}
</style>
