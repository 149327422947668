<template>
	<div class="app" v-if="loaded">
		<browserPopup v-if="popupBrowser" />
		<navigation />
		<router-view />
		<!-- <appBlock /> -->
		<footercomp />
	</div>
	<div class="loading" v-else>
		<loadingAnimation />
	</div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";

import loadingAnimation from "@/components/loading/loading";
import browserPopup from "@/components/popup/browser";
import navigation from "@/components/_layout/navigation";
// import appBlock from "@/components/blocks/app";
import footercomp from "@/components/_layout/footer";

export default {
	name: "App",
	components: {
		loadingAnimation,
		browserPopup,
		navigation,
		// appBlock,
		footercomp,
	},
	setup() {
		const store = useStore();

		/* ----- Data -------------------- */
		const loaded = ref(computed(() => store.state.pageContent.loaded));
		const popupBrowser = ref(computed(() => store.state.popupBrowser.data.popup));
		const navigation = ref(computed(() => store.state.navigation.menu));

		store.commit("language/checkLanguage");
		store.commit("popupBrowser/checkStatus");
		store.commit("navigation/getNavigationMenu");

		// Methods
		const getPages = () => {
			let currentUrl = window.location.href.split("/")[3];

			if (store.state.navigation.menu.length > 0) {
				if (currentUrl === "") {
					setTimeout(() => {
						store.commit("pageContentHome/getData");
					});
				} else {
					let links = store.state.navigation.menu;
					let currentZone;

					for (let i = 0; i < links.length; i++) {
						if (links[i].link === currentUrl) {
							currentZone = links[i].zone;
						}
					}

					if (currentZone !== "") {
						store.commit("pageContent/getData", currentZone);
					} else {
						console.log("Not Found the page");
					}
				}
			} else {
				setTimeout(() => {
					getPages();
				}, 1000);
			}
		};

		/* ----- onMounted -------------------- */
		onMounted(() => {
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE

			getPages();

			// setTimeout(() => {
			// 	if (currentPage === "hiveLiteHomepage") {
			// 		store.commit("pageContentHome/getData");
			// 	} else {
			// 		store.commit("pageContent/getData", currentPage);
			// 	}
			// }, 1000);
		});

		useHead({
			title: "Loading" + store.state.siteTitle,
			siteTitle: " - Hive UPS",
			meta: [
				{
					name: `description`,
					content: "hello",
				},
			],
		});

		return {
			loaded,
			popupBrowser,
			navigation,
		};
	},
};
</script>

<style lang="scss"></style>
