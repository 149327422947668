import store from "@/_store";

const state = () => ({
	language: "en-GB",
});

const mutations = {
	checkLanguage(state) {
		if (localStorage.lang) {
			state.language = localStorage.lang;
		} else {
			localStorage.lang = state.language;
		}
	},
	setLanguage(state, data) {
		state.language = data;
		localStorage.lang = data;

		store.commit("navigation/getNavigationMenu");
		store.commit("pageContentHome/getData");

		let currentUrl = window.location.href.split("/")[3];
		let links = store.state.navigation.menu;
		let currentZone;

		for (let i = 0; i < links.length; i++) {
			if (links[i].link === currentUrl) {
				currentZone = links[i].zone;
			}
		}

		store.commit("pageContent/getData", currentZone);
	},
};

// actions
const actions = {
	checkLanguage(context) {
		context.commit("checkLanguage");
	},
	closePopup(context, language) {
		context.commit("closePopup", language);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
