import store from "@/_store";
import axios from "axios";
import marked from "marked";

const state = () => ({
	pageTitle: "loading",
	header: null,
	data: null,
	loaded: false,
	pageColour: "",
});

const mutations = {
	getData(state, data) {
		let content = [];
		let links = [];
		let linksBig = [];
		let assets = [];
		let reBuild;

		axios({
			method: "get",
			url:
				"https://preview.contentful.com/spaces/b9cqxo9t02fq/environments/master/entries?access_token=Qh_7dhMqrxmWrDe2zFMk-WCpI9pj9Hm0upPX_DHPBeI&content_type=hiveLitePage&fields.zone=" +
				data +
				"&include=2&locale=" +
				store.state.language.language,
			headers: {
				"content-type": "application/json",
			},
		})
			.then((response) => {
				let data = response.data.includes.Entry;
				state.pageTitle = response.data.items[0].fields.displayTitle;
				state.pageColour = response.data.items[0].fields.pageColor;
				let filePath = response.data.items[0].fields.mediaPath;

				let linkURL = "UPS_Content_Dropbox/" + filePath + "/";

				for (let i = 0; i < data.length; i++) {
					if (
						(data[i].fields.descriptionText &&
							data[i].fields.descriptionText !== null &&
							data[i].fields.descriptionText !== "") ||
						(data[i].fields.displayName &&
							data[i].fields.displayName !== null &&
							data[i].fields.displayName !== "") ||
						(data[i].fields.blockTitle &&
							data[i].fields.blockTitle !== null &&
							data[i].fields.blockTitle !== "")
					) {
						data[i].fields.uId = data[i].sys.id;
						content.push(data[i].fields);
					}
					if (data[i].fields.linkURL && data[i].fields.linkURL !== null && data[i].fields.linkURL !== "") {
						links.push(data[i].fields);
						linksBig.push(data[i]);
					}
					if (data[i].fields.mediaURL && data[i].fields.mediaURL !== null && data[i].fields.mediaURL !== "") {
						assets.push(data[i]);
					}
				}

				for (let i = 0; i < content.length; i++) {
					let order = response.data.items[0].fields.hotspotContent;

					if (order) {
						for (let o = 0; o < order.length; o++) {
							if (order[o].sys.id === content[i].uId) {
								content[i].orderBy = o;
							}
						}
					}
				}

				for (let i = 0; i < content.length; i++) {
					content[i].buttons = [];
					content[i].images = [];
					content[i].videos = [];

					for (const objectData in content[i]) {
						reBuild = "";
						if (`${content[i][objectData]}`.includes("#") || `${content[i][objectData]}`.includes("\n")) {
							content[i][objectData] = marked(`${content[i][objectData]}`);
						}
						if (`${content[i][objectData]}`.includes("{{")) {
							let splitRule = /{{(.*?)}}/;
							let split = `${content[i][objectData]}`.split(splitRule);

							for (let s = 0; s < split.length; s++) {
								for (let l = 0; l < links.length; l++) {
									if (links[l].id === split[s]) {
										split[s] = "";
									}
								}
							}
							for (let s = 0; s < split.length; s++) {
								reBuild = reBuild + split[s];
							}
							content[i][objectData] = reBuild
								.replace("undefined", "")
								.replace("# ", "")
								.replace("#", "")
								.replace("\n\n", "<br><br>")
								.replace("\n", "<br>");
						}
					}

					if (content[i].mediaContent) {
						for (let m = 0; m < content[i].mediaContent.length; m++) {
							for (let a = 0; a < assets.length; a++) {
								if (content[i].mediaContent[m].sys.id === assets[a].sys.id) {
									if (assets[a].fields.type === "Image") {
										let mediaObject = {
											mediaTitle: assets[a].fields.mediaTitle,
											mediaDescription: assets[a].fields.mediaDescription,
											mediaURL: linkURL + assets[a].fields.mediaURL,
										};
										content[i].images.push(mediaObject);
									} else if (assets[a].fields.type === "Video") {
										let mediaObject = {
											mediaTitle: assets[a].fields.mediaTitle,
											mediaDescription: assets[a].fields.mediaDescription,
											mediaURL: linkURL + assets[a].fields.mediaURL,
										};
										content[i].videos.push(mediaObject);
									}
								}
							}
						}
					}
					if (content[i].linkedContent) {
						for (let c = 0; c < content[i].linkedContent.length; c++) {
							for (let a = 0; a < linksBig.length; a++) {
								if (content[i].linkedContent[c].sys.id === linksBig[a].sys.id) {
									content[i].buttons.push({
										buttonText: linksBig[a].fields.linkTitle,
										buttonLink: linksBig[a].fields.linkURL,
									});
								}
							}
						}
					}
				}

				for (let i = 0; i < content.length; i++) {
					if (content[i].actionValue && content[i].actionValue !== "") {
						content[i].actionValue = linkURL + content[i].actionValue;
					}
				}

				for (let i = 0; i < content.length; i++) {
					if (content[i].blockStyle && content[i].blockStyle !== "") {
						content[i].blockType = content[i].blockStyle;
					} else {
						if (content[i].descriptionText) {
							content[i].blockType = "Expanded";
						} else {
							content[i].blockType = "Text";
						}
					}
				}

				for (let i = 0; i < content.length; i++) {
					if (content[i].blockType === "Expanded") {
						if (!content[i].displayName) {
							content[i].displayName = content[i].blockTitle;
							content[i].descriptionText = marked(content[i].blockText);
						}
					}
					if (content[i].blockType === "Promo") {
						if (content[i].actionValue) {
							if (content[i].actionValue.includes(".mp4")) {
								content[i].blockType = "vidoeBlock";
								content[i].videos = [{}];
								content[i].videos[0].mediaURL = content[i].actionValue;
							} else if (content[i].actionValue.includes(".pdf")) {
								content[i].blockType = "pdfBlock";
								content[i].descriptionText = marked(content[i].descriptionText);
								content[i].pdfFile = content[i].actionValue;
								content[i].pdfImage = content[i].actionValue.replace(".pdf", ".jpg");
							}
						} else if (content[i].mediaUrl) {
							if (content[i].mediaUrl.includes(".mp4")) {
								content[i].displayName = content[i].blockTitle;
								content[i].descriptionText = marked(content[i].blockText);
								content[i].blockType = "vidoeBlock";
								content[i].videos = [{}];
								content[i].videos[0].mediaURL = content[i].mediaUrl;
							} else if (content[i].mediaUrl.includes(".pdf")) {
								content[i].blockType = "pdfBlock";
								content[i].descriptionText = marked(content[i].descriptionText);
								content[i].pdfFile = content[i].mediaUrl;
								content[i].pdfImage = content[i].mediaUrl.replace(".pdf", ".jpg");
							}
						} else {
							content[i].blockType = "Text";
							content[i].blockText = content[i].descriptionText;
						}
					}
				}

				let currentUrl = window.location.href.split("/")[3];
				let navLinks = store.state.navigation.menu;

				for (let i = 0; i < navLinks.length; i++) {
					console.log(navLinks[i]);
					if (navLinks[i].link === currentUrl) {
						state.header = navLinks[i].sliderImage;
						state.header.text = navLinks[i].heroCopy;
					}
				}

				state.data = content;
				state.loaded = true;
			})
			.catch((err) => {
				console.log(err);
			});
	},
	loaded(state) {
		state.loaded = true;
	},
};

// actions
const actions = {
	getData(context, data) {
		context.commit("getData", data);
	},
	loaded(context) {
		context.commit("loaded");
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
