import { createStore } from "vuex";

import language from "./components/language";
import navigation from "./components/navigation";
import popupBrowser from "./components/popupBrowser";
import pageContentHome from "./components/pageContentHome";
import pageContent from "./components/pageContent";
import popupVideo from "./components/popupVideo";

export default createStore({
	state: {
		api: "",
		siteTitle: " - Hive UPS",
	},
	mutations: {},
	actions: {},
	modules: {
		language,
		navigation,
		popupBrowser,
		pageContentHome,
		pageContent,
		popupVideo,
	},
});
