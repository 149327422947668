<template>
	<footer>
		<div class="topFooter">
			<div class="container">
				<div class="row">
					<div class="col-md-3 company">
						<img src="/images/upsLogo.svg" alt="HIVE Logo" />
						<!-- <p>{{ content.companyText }}</p> -->
					</div>
					<div class="col-md links">
						<h4>Navigation</h4>
						<!-- <h4>&nbsp;</h4> -->
						<div class="splitter"></div>
						<a v-for="item in menuLinks" :key="item.id" :href="item.link">
							<p v-if="!item.zone.includes('GAN')">{{ item.name }}</p>
						</a>
					</div>
					<div class="col-md links">
						<h4>&nbsp;</h4>
						<div class="splitter"></div>
						<a v-for="item in menuLinks" :key="item.id" :href="item.link">
							<p v-if="item.zone.includes('GAN')">{{ item.name }}</p>
						</a>
					</div>
					<div class="col-md-2"></div>
					<div class="col-md-1">
						<img class="expo" src="/images/Expo_2020_White.svg" alt="" />
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="splitter mt-30"></div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="flex bottomTopFooter">
							<div class="image">
								<img src="/images/edge_logo.png" alt="" />
							</div>
							<div class="image">
								<img src="/images/Opera_Logo.png" alt="" />
							</div>
							<div class="image">
								<img src="/images/Chrome_Logo.png" alt="" />
							</div>
							<div class="image">
								<img src="/images/Firefox_logo.png" alt="" />
							</div>
							<p v-html="content.browserText"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottomFooter">
			<div class="container">
				<div class="row flex">
					<div class="col-md-6">
						<p class="links">
							<a v-for="item in content.bottomMenu" :key="item.id" :href="item.link">{{ item.name }}</a>
						</p>
						<p class="copyright" v-html="content.copyright"></p>
					</div>
					<div class="col-md-6">
						<p class="madeby">This Virtual Event is powered by <span>REYDAR</span></p>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
	name: "Footer",
	setup() {
		const store = useStore();
		/* ----- Data -------------------- */
		const menuLinks = ref(computed(() => store.state.navigation.menu));
		var content = ref({
			companyText:
				"Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreer dolore.",
			browserText:
				'<strong>Access HIVE by UPS Immersive Experience by changing your browser</strong><br>The HIVE by UPS Virtual Event is a highly immersive 3D experience. Please re-visit using the latest version of <a href="https://www.google.com/chrome/">Chrome</a>, <a href="https://www.microsoft.com/edge">Edge</a>, <a href="https://www.mozilla.org/firefox/new/">Firefox</a> or <a href="https://www.opera.com/">Opera</a> browsers.',
			copyright: "Copyright &copy; 2021 HIve by UPS. All Rights Reserved.",
			bottomMenu: [
				// {
				// 	id: 1,
				// 	name: "UPS",
				// 	link: "#ups",
				// },
				// {
				// 	id: 2,
				// 	name: "Cookie Policy",
				// 	link: "#cookiepolicy",
				// },
				{
					id: 3,
					name: "Privacy Policy",
					link: "https://hive-staging.enginecloud.co.uk/privacy",
				},
				// {
				// 	id: 4,
				// 	name: "Terms & Conditions",
				// 	link: "#termsconditions",
				// },
			],
		});

		return {
			content,
			menuLinks,
		};
	},
};
</script>

<style lang="scss">
footer {
	.topFooter {
		position: relative;
		width: 100%;
		background-color: var(--footer);
		padding-top: 90px;
		padding-bottom: 45px;

		h4 {
			font-size: 16px;
			font-weight: 700;
			color: var(--background);
			margin: 0;
			margin-bottom: 30px;
		}
		p {
			font-size: 15px;
			font-weight: 400;
			color: var(--background);
			margin: 0;
			margin-bottom: 15px;
		}

		img.expo {
			max-width: 100%;
		}

		.company {
			img {
				width: 100%;
				max-height: 60px;
				object-fit: contain;
				object-position: left;
				margin-bottom: 30px;
			}
			p {
				font-size: 16px;
				font-weight: 400;
				color: var(--background);
				margin: 0;
			}
		}
		.links {
			a {
				text-decoration: none;

				p {
					margin-bottom: 15px;
				}
				.splitter {
					margin-top: 30px;
				}
			}
		}
		.social {
			.socialIcons {
				display: flex;
				align-content: center;
				align-items: center;

				i {
					font-size: 26px;
					color: var(--background);
					margin-right: 15px;
				}
			}
		}
		.flex {
			display: flex;
			align-content: center;
			align-items: center;
		}
		.bottomTopFooter {
			.image {
				position: relative;
				width: 40px;
				height: 40px;
				margin-right: 15px;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
			p {
				font-size: 14px;
				font-weight: 400;
				color: var(--background);
				margin: 0;
				margin-left: 15px;

				a {
					color: var(--background);
					text-decoration: underline;
				}
			}
		}
	}
	.bottomFooter {
		background-color: var(--background-alt);
		padding: 20px 0;

		.flex {
			display: flex;
			align-items: center;
			align-content: center;
		}

		p.links {
			font-size: 14px;
			font-weight: 700;
			color: var(--text-);
			margin: 0;
			margin-bottom: 3px;

			a {
				position: relative;
				color: var(--text);
				margin-right: 10px;
			}
			a:after {
				content: "|";
				padding-left: 10px;
			}
		}
		p.copyright {
			font-size: 14px;
			font-weight: 400;
			color: #565656;
			margin: 0;
		}
		p.madeby {
			font-size: 16px;
			font-weight: 400;
			text-align: right;
			color: #565656;
			margin: 0;

			span {
				font-family: proxima-nova, sans-serif;
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	footer {
		.topFooter {
			padding-top: 45px;
			.company {
				img {
					margin-bottom: 15px;
				}
			}
			img.expo {
				max-width: 50%;
			}
			h4 {
				margin-top: 30px;
			}
			.splitter {
				margin-top: 15px;
			}
			.bottomTopFooter {
				flex-wrap: wrap;

				.image {
					width: 30px;
					height: 30px;
					margin-right: 15px;
					margin-top: 15px;
				}
				.image:first-child {
					width: 30px;
					height: 30px;
				}
				p {
					margin-top: 15px;
					margin-left: 0px;
				}
			}
			.socialIcons {
				margin-bottom: 15px;
			}
		}
		.bottomFooter {
			p.madeby {
				text-align: center;
				margin-top: 15px;
				margin-bottom: 15px;
			}
			.splitter {
				margin-top: 30px;
			}
		}
	}
}
</style>
