const state = () => ({
	data: {
		popup: false,
	},
});

const mutations = {
	checkStatus(state) {
		if (localStorage.HIVE_Browser_Support_Popup) {
			if (localStorage.HIVE_Browser_Support_Popup === "false") {
				state.data.popup = false;
				document.body.style.overflowY = "auto";
			}
		}
	},
	closePopup(state) {
		state.data.popup = false;
		document.body.style.overflowY = "auto";
		localStorage.setItem("HIVE_Browser_Support_Popup", "false");
	},
};

// actions
const actions = {
	checkStatus(context) {
		context.commit("checkStatus");
	},
	closePopup(context) {
		context.commit("closePopup");
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
